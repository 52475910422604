<template>
    <div class="row">
        <div class="col form-group">
            <label for="searchfield_campaign" class="mb-1">{{ $tc('general.campaign', 2) }} (Keep empty to fetch all)</label>
            <v-select
                v-model="campaign"
                label="name"
                class="form-control"
                :filterable="true"
                :reduce="(entities) => entities._id"
                :options="allCampaigns"
                @input="reportInput('campaign')"
                :multiple="true"
                :close-on-select="false"
            >
                <template slot="no-options">
                {{ $t("messages.typetosearch", {name: getSearchString()} ) }}
                </template>
            </v-select>
        </div>
        <div class="col form-group">
            <label for="searchfield_daterange" class="mb-1">{{ $t('general.startdate') }} / {{ $t('general.enddate') }} (max. 3 {{ 
           $tc("general.day",2) }})</label>
            <DateRangePickerCustom opens="right" @update="setDates" />
        </div>
    </div>
</template>
  
<script>
  import DateRangePickerCustom from "@/components/dashboard/components/DateRangePicker.vue";
import { mapGetters } from 'vuex';

  export default {
    name: "EntityFilterOptimizer",
    components: {
        DateRangePickerCustom
    },
    props: {
        filter: {
            type: Object,
            required: false
        },
    },
    async created(){

        const campaigns = await this.$api.optimizer.getCampaigns();

        for(const c of campaigns){
            if(c.sticky) c.name += " (sticky)";
            this.allCampaigns.push(c);
        }

        if(this.filter){
            if(this.filter.campaign)        this.campaign = this.filter.campaign;
        }
    },
    data() {
      return {
        campaign: [],
        allCampaigns: [],
      };
    },
    computed: {
        ...mapGetters(["dateRange"]),
        dateRange: {
            get() {
                return this.$store.state.store.dateRange;
            }
        },
    },
    methods: {
        getSearchString(){
            return this.$te(`general.${this.entity}`) ? this.$tc(`general.${this.entity}`,2) : this.entity;
        },
        setDates(){
            this.$emit("filter", 'start', this.dateRange.startDate);
            this.$emit("filter", 'end', this.dateRange.endDate);
        },
        reportInput(field){
            this.$emit("filter", field, this[field]);
        },
    },
  };
</script>
