<template>
  <div class="row">
    <div class="col-lg-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">

          <b-dropdown
            variant="outline-white"
            class="pull-right ml-1"
            no-caret
            offset="-100"
            v-if="isSysOp && entityType != 'sync-queue'"
          >
              <template #button-content>
                <i class="fa fa-bars"></i>
              </template>
            <b-dropdown-item @click.native.capture.stop>
              <b-form-checkbox v-model="localArchive" name="check-button">{{ $t("labels.show-deleted") }}</b-form-checkbox>
            </b-dropdown-item>
          </b-dropdown>

          <button class="btn btn-success pull-right" @click="gotoNewEntity" v-if="!isAccounting && entityType != 'sync-queue'">
            <i class="fa fa-plus"></i>{{ entityData.createButton }}
          </button>

          <b-button
          v-if="entityType === 'graphic'"
          variant="success"
          class="mr-2 pull-right"
          @click.prevent="$bvModal.show('folder-modal-create')"
          >
          <i class="fa fa-plus mr-0"></i>
          Create Folder
          </b-button>
          <h4 class="card-title">{{ entityData.title }}</h4>
          <br />

          <div class="alert alert-danger" v-if="error">{{ error }}</div>
          <div class="alert alert-warning" v-if="warning">{{ warning }}</div>
          <div class="alert alert-success" v-if="success">{{ success }}</div>

          <div v-if="firstLoading" class="flip-square-loader"></div>
          <div v-else><!-- search and filter options -->
            <EntityFilterBanner v-if="entityType == 'banner'" @filter="addFilter" />
            <EntityFilterBookingtemplate v-if="entityType == 'bookingtemplate' && isSysOp" @filter="addFilter" />
            <EntityFilterEvents v-if="entityType == 'event'" :filter="filter" @filter="addFilter" />
            <EntityFilterLogins v-if="entityType == 'auth'" :filter="filter" @filter="addFilter" />
            <EntityFilterSyncQueue v-if="entityType == 'sync-queue'" :filter="filter" @filter="addFilter" />
            <EntityFilterCampaignlogs v-if="entityType == 'campaignlog'" :filter="filter" @filter="addFilter" />
            <EntityFilterSegment v-if="entityType == 'segment'" :filter="filter" @filter="addFilter" />
            <EntityFilterBannertemplates v-if="entityType == 'bannertemplate'" :filter="filter" @filter="addFilter" />
            <EntityFilterCis v-if="entityType == 'ci'" :filter="filter" @filter="addFilter" />
            <EntityFilterDateOnly v-if="entityType == 'accesslog'" />
            <EntityFilterCatalogs v-if="entityType == 'catalog'" :filter="filter" @filter="addFilter" />
            <EntityFilterGraphics v-if="entityType == 'graphic'" :filter="filter" @filter="addFilter" @openFolder="openFolder" :folders="folders" :currentFolder="currentFolder" />

            <div class="row bg-light pt-3">
              <div class="col-12 form-group">
                <label for="search" class="mb-1">{{ $t("general.searchfor")}}</label>
                <input
                  id="search"
                  class="form-control"
                  v-model="filter.search"
                  @keydown.enter="changePage(1)"
                />
              </div>
            </div>
            <div class="row bg-light py-3">
              <div class="col text-right">
                  <b-button variant="success" :disabled="settings.isBusy" @click="changePage(1)">{{ $t("general.search") }}</b-button>
              </div>
            </div>
          </div> <!-- end search and filter options -->

          <div class="mt-4" v-if="!itemCount && !firstLoading">
            <div v-if="settings.isBusy" class="flip-square-loader"></div>
            <em v-else>{{ entityData.noItems }}</em>
          </div>
          <div v-else-if="!firstLoading"><!-- table start -->
            <Pagination class="my-4" :itemCount="itemCount" :currentPage="settings.page" :perPage="filter.qty" @changeQuantity="changeQuantity" @changePage="changePage"></Pagination>
            <div class="table-responsive">
              <EntityTable
              :entities="items"
              :type="entityType"
              :populates="entityPopulate"
              :fields="entityFields"
              :lookups="entityLookup"
              :busy="settings.isBusy"
              :currentFolder="currentFolder"
              @openFolder="openFolder"
              @folderUpdated="foldersChanged"
              @sort="sortData"
              @reload="fetchData"
            />
            </div>
            <Pagination class="my-4" :itemCount="itemCount" :currentPage="settings.page" :perPage="filter.qty" @changeQuantity="changeQuantity" @changePage="changePage"></Pagination>
          </div> <!-- table end -->
        </div>
            <b-modal id="folder-modal-create" :hide-footer="true" :title="$t('actions.createx', {name: currentFolderName ? `${$tc('general.folder', 1)} in ${currentFolderName}` : $tc('general.folder',1)})">
              <CreateFolderModal :parentFolder="currentFolder" @created="foldersChanged" />
            </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import EntityTable from "./EntityTable";
import { mapGetters } from "vuex";
import Pagination from "@/components/pages/assets/Pagination.vue";
import EntityFilterBanner from "./assets/EntityFilterBanner";
import EntityFilterBookingtemplate from "./assets/EntityFilterBookingtemplate";
import EntityFilterEvents from "./assets/EntityFilterEvents";
import EntityFilterLogins from "./assets/EntityFilterLogins";
import EntityFilterSyncQueue from "./assets/EntityFilterSyncQueue";
import EntityFilterCampaignlogs from "./assets/EntityFilterCampaignlogs";
import EntityFilterSegment from "./assets/EntityFilterSegment";
import EntityFilterBannertemplates from "./assets/EntityFilterBannertemplates.vue";
import EntityFilterCis from "./assets/EntityFilterCis.vue";
import EntityFilterCatalogs from './assets/EntityFilterCatalogs.vue';
import EntityFilterGraphics from './assets/EntityFilterGraphics.vue';
import CreateFolderModal from '@/components/modals/CreateFolderModal';
import EntityFilterDateOnly from './assets/EntityFilterDateOnly.vue';

const jwtDecode = require('jwt-decode');

export default {
  name: "Entities",
  data() {
    return {
      firstLoading: true,
      settings: {
        isBusy: false,
        page: 1,
      },
      filter: {
        entity: null,
        qty: 10,
        offset: 0,
        sort: 'created',
        direction: 'DESC',
        search: null,
        advertiser: null,
        campaign: this.$route.query.campaign ? [this.$route.query.campaign] : null,
        lineitem: null,
        contract: null,
        populate: '[]',
        lookup: '[]',
        returnCount: true,
        catalog: this.$route.query.catalog ? this.$route.query.catalog : null,
        start: null,//this.entityType == 'event' ? new Date(this.dateRange.startDate).getTime() : null,
        end: null, //this.entityType == 'event' ? new Date(this.dateRange.endDate).getTime() : null,
        cls: this.$route.query.cls ? this.$route.query.cls : null,
        type: null,
        folder: null,
        host: null,
      },
      doNotFetchCountOnPageChange: [
        'transaction', 'banner', 'event', 'sync-queue', 'campaignlog'
      ],
      itemCount: 0,
      items: [],
      localArchive: false,
      folders: []
    };
  },
  watch: {
    localArchive: function(){
      this.$store.dispatch("setArchived", this.localArchive);
      this.$store.dispatch("setLoadingActive");
      this.changePage();
      this.$store.dispatch("setLoadingInactive");
    }
  },
  computed: {
    ...mapGetters([
      "pagination",
      "paginationSelectOptions",
      "loading",
      "error",
      "success",
      "warning",
      "archived",
      "user"
    ]),
    isSysOp: () => jwtDecode(localStorage.getItem(process.env.VUE_APP_JWT)).role == "systemoperator" ? true : false,
    isAccounting: () => jwtDecode(localStorage.getItem(process.env.VUE_APP_JWT)).role == "accounting" ? true : false,
    dateRange: {
      get() {
          return this.$store.state.store.dateRange;
      }
    },
    entityType() {
      return this.$router.history.current.meta.entity == 'queue1' ? 'syncqueue' : this.$router.history.current.meta.entity;
    },
    entityPopulate() {
      return this.$router.history.current.meta.populate;
    },
    entityFields() {
      return this.$router.history.current.meta.fields;
    },
    entityLookup() {
      return this.$router.history.current.meta.lookup;
    },
    entityData(){

      let itemName = this.$router.history.current.meta.entity;
      let itemsName = itemName;

      if(this.$router.history.current.meta.entity == 'auth'){
        itemName = itemsName = this.$tc(`general.user`);
      }
      else if(this.$te(`general.${itemName}`)){
        itemName = this.$tc(`general.${itemName}`, 1);
        itemsName = this.$tc(`general.${itemsName}`, 2);
      }

      return {
        title: itemsName,
        createButton: this.$t("actions.createx", {name: itemName}),
        noItems: this.$t("messages.noitems", {name: itemName}),
      }
    },
    currentFolder() {
      if (!this.folders?.length) return null;
      return this.folders[this.folders.length - 1]._id;
    },
    currentFolderName() {
      if (!this.folders?.length) return null;
      return this.folders[this.folders.length - 1].name;
    }
  },
  components: {
    EntityTable,
    Pagination,
    EntityFilterBanner,
    EntityFilterBookingtemplate,
    EntityFilterEvents,
    EntityFilterLogins,
    EntityFilterSyncQueue,
    EntityFilterCampaignlogs,
    EntityFilterDateOnly,
    EntityFilterSegment,
    EntityFilterBannertemplates,
    EntityFilterCis,
    EntityFilterCatalogs,
    EntityFilterGraphics,
    CreateFolderModal
  },
  async created() {
    this.filter.entity = this.entityType;

    if(['event', 'campaignlog', 'accesslog'].includes(this.entityType)){
      this.filter.start = new Date(this.dateRange.startDate).getTime();
      this.filter.end = new Date(this.dateRange.endDate).getTime();
    }
    else if(this.entityType == 'sync-queue'){
      this.filter.status = ['not run', 'failed'];
    }
    
    if(this.$route?.query?.search){
      this.filter.search = this.$route.query.search;
    }

    const user = await this.$api.user.get();
    if(this.entityType == 'advertiser' && user?.parent && user?.role != "co_worker"){
      this.$router.push('/403')
    }
    this.localArchive = this.$store.getters.archived;
    this.fetchData();
  },
  methods: {
    openFolder(folder) {
      console.log(folder, this.folders);
      if (folder === null) {
        this.folders = [];
      } else {
        const index = this.folders.findIndex((f) => {
          return f?._id == folder?._id && typeof f?._id !== 'undefined'
        });
        console.log(index, this.folders, folder);
        if (index === -1) {
          this.folders.push(folder);
        } else {
          this.folders = this.folders.slice(0, index+1) 
        }
      }
      this.filter.folder = this.currentFolder;
      this.fetchData();
    },
    foldersChanged() {
      this.$bvModal.hide('folder-modal-create')
      this.$bvModal.hide('folder-modal');
      this.fetchData();
    },
    addFilter(field, value){
      this.filter[field] = value;
    },
    sortData(sort){
      if(sort.sortBy == '') return;
      this.filter.sort = sort.sortBy;
      this.filter.direction = sort.sortDesc === false ? 'ASC' : 'DESC';
      this.changePage(1);
    },
    changePage(page){
      this.settings.page = page;
      this.fetchData();
    },
    changeQuantity(quantity){
      this.filter.qty = quantity;
      this.changePage(1);
    },
    async fetchData(){
      if(this.settings.isBusy)  return;

      this.settings.isBusy = true;
      this.filter.offset = (this.settings.page-1)*this.filter.qty;

      this.filter.populate = JSON.stringify(this.entityPopulate);
      this.filter.lookup = JSON.stringify(this.entityLookup);

      //this.filter.returnCount = this.settings.page == 1 || !this.doNotFetchCountOnPageChange.includes(this.entityType);
      this.filter.returnCount = this.settings.page == 1 || this.itemCount < 10000;

      if(this.entityType == 'auth' && this.filter.sort == 'created'){
        this.filter.sort = '_id';
      }
      
      if (this.filter.folder === null) this.filter.folder = 'null'
      const response = await this.$api.entity.getEntities(this.entityType, this.filter)
      if (this.filter.folder === 'null') this.filter.folder = null;

      let additional = [];

      if (this.entityType === 'graphic') {
        additional = await this.$api.graphic.listFolders(this.filter?.folder || null, {
          enableLoading: false,
        });
        additional = additional.slice((this.filter.offset || 0)*(this.filter?.qty || 0), (this.filter.offset || 0)*(this.filter?.qty || 0)+(this.filter?.qty || 0)).map((folder) => {
          return {
            _id: folder._id,
            downloadLink: 'folder',
            name: folder.name,
            folder: [{ name: folder.name }],
          }
        })
        response.items = [...additional, ...response.items];
        if (typeof response.count !== "undefined") response.count = response.count + additional.length;
      }

      if(this.filter.returnCount) this.itemCount = response.count;

      this.items = response.items;

      this.settings.isBusy = false;
      this.firstLoading = false;
    },
    gotoNewEntity() {
      if (this.$route.query.catalog) {
        this.$router.push(`${this.entityType}/new/${this.$route.query.catalog}`);
      } else if(this.entityType == 'bookingtemplate' || this.entityType == 'lineitem'){
        this.$router.push(`${this.entityType}/create`);
      } else if(this.entityType == 'auth'){
        this.$router.push(`login/new`);
      } else {
        this.$router.push(`${this.entityType}/new`);
      }
    },
  }
};
</script>

<style scoped>
</style>