<template>
  <div>
    <div class="row" v-if="(getRole == 'systemoperator' || getRole == 'wizard' || getRole == 'affiliatemanager')">
        <div class="col-12">
            <h3>
              <span class="handwriting mr-2">Campaign Optimization</span>
            </h3>
        </div>
    </div>
    <div class="row">
      <div class="col-12 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-7 offset-4">
                  <EntityFilterOptimizer :filter="filter" @filter="addFilter" />
                </div>
                <div class="col-1 align-self-center">
                  <b-button @click="updateDashboard" :disabled="generatingTable" variant="primary" class="float-right">Update</b-button>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>

    <div v-show="generatingTable" class="flip-square-loader"></div>
    <div class="row" v-show="!generatingTable && tableData.length > 0">
        <div
            class="col-md-12 grid-margin stretch-card"
            v-if="(getRole == 'systemoperator' || getRole == 'wizard' || getRole == 'affiliatemanager')"
        >
            <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col"><h4 class="card-title">Campaign Optimization</h4></div>
                    <div class="col my-auto text-right">
                      <b-form-checkbox
                        v-model="extendedView"
                        :unchecked-value="false"
                      >Extended View</b-form-checkbox>
                    </div>
                    <div class="col-auto">
                      <b-dropdown
                        variant="outline-white"
                        :html="'Filter Actions'"
                        class="reportings-box my-1"
                      >
                        <b-dropdown-item @click.native.capture.stop v-for="(item, index) in filterItems" :key="index">
                          <b-form-checkbox v-model="item.status" name="check-button">{{item.text}} ({{item.elements}})</b-form-checkbox>
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </div>
                    <div class="table-responsive mt-2 table-opt">
                      <b-table hover sticky-header="2000px" :items="tableData" :fields="tableFields">
                        <template #thead-top>
                          <b-tr>
                            <b-th :colspan="tableHeaderCols.campaign" class="text-left" variant="campaign">Campaign</b-th>
                            <b-th :colspan="tableHeaderCols.suite" class="text-center" variant="suite">ADSuite</b-th>
                            <b-th :colspan="tableHeaderCols.network" class="text-center" variant="network">Network</b-th>
                            <b-th :colspan="tableHeaderCols.candc" class="text-center" variant="candc">Costs &amp; Commissions</b-th>
                            <b-th :colspan="tableHeaderCols.actions" class="text-center" variant="actions"> </b-th>
                          </b-tr>
                        </template>


                        <!-- Campaign -->
                        <template #cell(campaign_name)="data">
                          <span>
                          <i
                            :class="'mr-2 fa ' + getStatus(data.item.optimizer)"
                          ></i>
                          <i
                            class="fa fa-copy mr-2"
                            :title="'Copy ID to clipboard: ' + data.item.campaign._id"
                            style="cursor: pointer"
                            @click="copyIdToClipboard(data.item.campaign._id)"
                          ></i>
                          {{ data.item.campaign.name }}</span>
                          <span class="mt-3 d-block text-small">
                            <router-link target="_blank" v-for="(link, index) in getLinks(data.item)" :key="index" :to="link.to" :title="link.title" class="mr-2" :class="link.class"><i :class="link.icon"></i></router-link>
                            <i @click="showMctCalculator(data.item.campaign._id)" class="fa fa-rocket text-black cursor-pointer" title="Calculate MCT"></i>
                          </span>
                        </template>

                        <template #cell(network)="data">
                          <span class="text-small">{{data.item.revenueSource.toUpperCase()}}</span><br><span class="mt-1 d-block text-small">{{data.item.revenueSourceId}}</span>
                        </template>

                        <template #cell(ordervalue)="data">
                          <span v-if="data.value > 1000" :title="data.value">&euro; {{ data.value | numeral('0.0a') }}</span>
                          <span v-else>&euro; {{ data.value }}</span>
                        </template>

                        <template #cell(shopCr)="data">
                          <span v-if="data.item.uniqueUsers > 0" :title="`Reached ${data.item.sales} sales with ${data.item.uniqueUsers} onsite unique users`">{{ NumRound(data.value,2) }} %</span>
                          <span v-else><i>no Data</i></span>
                        </template>
                        

                        <!-- ADSuite -->
                        <template #cell(impressions)="data">
                          <span v-if="data.value > 1000" :title="data.value">{{ data.value | numeral('0.0a') }}</span>
                          <span v-else>{{ data.value }}</span>
                        </template>

                        <template #cell(clicks)="data">
                          <span v-if="data.value > 1000" :title="data.value">{{ data.value | numeral('0.0a') }}</span>
                          <span v-else>{{ data.value }}</span>
                        </template>

                        <template #cell(opr)="data">
                          <span v-b-tooltip.hover :title="`Reached ${NumRound(data.value, 2)} OPRs of needed ${data.item.optimizer.qty*amountOfDays(1)} ⇨ ${NumRound(data.value*100/(data.item.optimizer.qty*amountOfDays(1)))} %`">
                            {{ data.value }} / {{ data.item.optimizer.qty*amountOfDays(1) }}
                            <b-progress :max="data.item.optimizer.qty*amountOfDays(1)" class="mt-1">
                              <b-progress-bar :variant="getProgressVariant(data.value, data.item.optimizer.qty)" :value="data.value == 0 ? data.item.optimizer.qty*amountOfDays(1) : data.value"></b-progress-bar>
                            </b-progress>
                          </span>
                        </template>

                        <template #cell(clickThroughRate)="data">
                          <span v-b-tooltip.hover :title="`Reached ${NumRound(data.value, 2)} % of needed ${data.item.optimizer.ctr} % ⇨ ${NumRound(data.value*100/data.item.optimizer.ctr)} %`">
                            {{ NumRound(data.value, 2) }} %
                            <b-progress :max="data.item.optimizer.ctr*2" class="mt-1">
                              <b-progress-bar :variant="getProgressVariant(data.value, data.item.optimizer.ctr)" :value="data.value == 0 ? data.item.optimizer.ctr*2 : data.value"></b-progress-bar>
                            </b-progress>
                          </span>
                        </template>

                        <template #cell(mct_pcConversionRate)="data">
                          <span v-b-tooltip.hover :title="`Reached ${NumRound(data.value, 2)} % of needed ${NumRound(data.item.shopCr+data.item.optimizer.crp,2)} % (${NumRound(data.item.shopCr,2)} + ${data.item.optimizer.crp}) ⇨ ${NumRound(data.value*100/(data.item.shopCr+data.item.optimizer.crp))} %`">
                            {{ NumRound(data.value, 2) }} %
                            <b-progress :max="(data.item.shopCr+data.item.optimizer.crp)*2" class="mt-1">
                              <b-progress-bar :variant="getProgressVariant(data.value, data.item.shopCr+data.item.optimizer.crp)" :value="data.value == 0 ? (data.item.shopCr+data.item.optimizer.crp)*2 : data.value"></b-progress-bar>
                            </b-progress>
                          </span>
                        </template>

                        <template #cell(mct_marketShare)="data">
                          <span v-b-tooltip.hover :title="`Reached ${NumRound(data.value, 2)} % of needed ${data.item.optimizer.ms} % ⇨ ${NumRound(data.value*100/data.item.optimizer.ms)} %`">
                            {{ NumRound(data.value, 2) }} %
                            <b-progress :max="data.item.optimizer.ms*2" class="mt-1">
                              <b-progress-bar :variant="getProgressVariant(data.value, data.item.optimizer.ms)" :value="data.value == 0 ? data.item.optimizer.ms*2 : data.value"></b-progress-bar>
                            </b-progress>
                          </span>
                        </template>

                        <!-- Network -->
                        <template #cell(networkImpressions)="data">
                          <span v-if="data.item.revenueSource == 'direct'">-</span>
                          <span v-else-if="data.value > 1000" :title="data.value">{{ data.value | numeral('0.0a') }}</span>
                          <span v-else>{{ data.value }}</span>
                        </template>

                        <template #cell(networkClicks)="data">
                          <span v-if="data.item.revenueSource == 'direct'">-</span>
                          <span v-else-if="data.value > 1000" :title="data.value">{{ data.value | numeral('0.0a') }}</span>
                          <span v-else>{{ data.value }}</span>
                        </template>

                        <template #cell(networkConversions)="data">
                          <span v-if="data.item.revenueSource == 'direct'">-</span>
                          <span v-else>{{ data.value }}</span>
                        </template>

                        <template #cell(networkClickThroughRate)="data">
                          <span v-if="data.item.revenueSource == 'direct'">-</span>
                          <span v-else v-b-tooltip.hover :title="`Reached ${NumRound(data.value, 2)} % of needed ${data.item.optimizer.ctr} % ⇨ ${NumRound(data.value*100/data.item.optimizer.ctr)} %`">
                            {{ NumRound(data.value, 2) }} %
                            <b-progress :max="data.item.optimizer.ctr*2" class="mt-1">
                              <b-progress-bar :variant="getProgressVariant(data.value, data.item.optimizer.ctr)" :value="data.value == 0 ? data.item.optimizer.ctr*2 : data.value"></b-progress-bar>
                            </b-progress>
                          </span>
                        </template>

                        <template #cell(networkConversionRate)="data">
                          <span v-if="data.item.revenueSource == 'direct'">-</span>
                          <span v-else v-b-tooltip.hover :title="`Reached ${NumRound(data.value, 2)} % of needed ${NumRound(data.item.shopCr+data.item.optimizer.crp,2)} % (${NumRound(data.item.shopCr,2)} + ${data.item.optimizer.crp}) ⇨ ${NumRound(data.value*100/(data.item.shopCr+data.item.optimizer.crp))} %`">
                            {{ NumRound(data.value, 2) }} %
                            <b-progress :max="(data.item.shopCr+data.item.optimizer.crp)*2" class="mt-1">
                              <b-progress-bar :variant="getProgressVariant(data.value, data.item.shopCr+data.item.optimizer.crp)" :value="data.value == 0 ? (data.item.shopCr+data.item.optimizer.crp)*2 : data.value"></b-progress-bar>
                            </b-progress>
                          </span>
                        </template>

                        <template #cell(networkMarketshare)="data">
                          <span v-if="data.item.revenueSource == 'direct'">-</span>
                          <span v-else v-b-tooltip.hover :title="`Reached ${NumRound(data.value, 2)} % of needed ${data.item.optimizer.ms} % ⇨ ${NumRound(data.value*100/data.item.optimizer.ms)} %`">
                            {{ NumRound(data.value, 2) }} %
                            <b-progress :max="data.item.optimizer.ms*2" class="mt-1">
                              <b-progress-bar :variant="getProgressVariant(data.value, data.item.optimizer.ms)" :value="data.value == 0 ? data.item.optimizer.ms*2 : data.value"></b-progress-bar>
                            </b-progress>
                          </span>
                        </template>

                        <!-- Costs and Commissions -->
                        <template #cell(realCosts)="data">
                          <span v-if="data.value > 1000" :title="data.value">&euro; {{ data.value | numeral('0.0a') }}</span>
                          <span v-else>&euro; {{ NumRound(data.value, 2) }}</span>
                        </template>

                        <template #cell(budget)="data">
                          <span v-if="data.item.optimizer.totalDailyBudget > 0" v-b-tooltip.hover :title="`Spent € ${NumRound(data.item.realCosts, 2)} of available budget of € ${NumRound(data.item.optimizer.totalRangeBudget,2)} (€ ${NumRound(data.item.optimizer.totalDailyBudget,2)} per day) ⇨ ${NumRound(data.item.realCosts*100/data.item.optimizer.totalDailyBudget)} %`">
                            {{ NumRound(data.item.realCosts*100/data.item.optimizer.totalDailyBudget,2) }} %
                            <b-progress :max="data.item.optimizer.totalRangeBudget" class="mt-1">
                              <b-progress-bar :variant="getProgressVariant(data.item.realCosts, data.item.optimizer.totalRangeBudget)" :value="data.item.realCosts"></b-progress-bar>
                            </b-progress>
                          </span>
                        </template>

                        <template #cell(mct_conversionValue)="data">
                          <span v-if="data.value > 1000" :title="data.value">&euro; {{ data.value | numeral('0.0a') }}</span>
                          <span v-else>&euro; {{ data.value }}</span>
                        </template>

                        <template #cell(networkRevenue)="data">
                          <span v-if="data.value > 1000" :title="data.value">&euro; {{ data.value | numeral('0.0a') }}</span>
                          <span v-else>&euro; {{ Math.round((data.value + Number.EPSILON) * 100) / 100 }}</span>
                        </template>

                        <!-- infos -->
                        <template #cell(infos)="data">
                          <b-badge class="d-block mb-1" v-for="(item, index) in data.value.danger" :key="'D'+index" variant="danger" :title="item.title">{{ item.message }}</b-badge>
                          <b-badge class="d-block mb-1" v-for="(item, index) in data.value.warning" :key="'W'+index" variant="warning" :title="item.title">{{ item.message }}</b-badge>
                          <b-badge class="d-block mb-1" v-for="(item, index) in data.value.info" :key="'I'+index" variant="info" :title="item.title">{{ item.message }}</b-badge>
                          <b-badge v-if="data.value.info.length+data.value.warning.length+data.value.danger.length == 0" variant="success"><i class="fa fa-thumbs-up"></i></b-badge>
                        </template>

                        <!-- Others -->
                        <template #cell()="data">{{data.value}}</template>

                      </b-table>
                    </div>
                </div>
            </div>
        </div>
        
        <!--<div
            class="col-lg-12 grid-margin stretch-card"
            v-if="(getRole == 'systemoperator' || getRole == 'wizard' || getRole == 'affiliatemanager') && !loading"
        >
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Access Logs</h4>
                    <p>Unauthorized accesses to tracking script</p>
                    <bar-chart :chart-data="accessLogsChart" :options="chartOptions" />

                    <div class="row">
                    <span v-for="(accessLog, alindex) in accessLogs" :key="alindex">
                        <router-link
                        :to="`/accesslogs?host=${accessLog.host}&start=${getStartTS}&end=${getEndTS}`"
                        >
                        <span
                            class="badge badge-light mr-2 mb-2"
                            style="color: #333"
                        >{{ accessLog.host }} ({{ accessLog.count }})</span>
                        </router-link>
                    </span>
                    </div>
                </div>
            </div>
        </div>-->
    </div>

    <b-modal
      id="mct-calculator-modal"
      :title="mctCalculatorTitle"
      :hide-footer="true"
    >
      <MctCalculatorModal :data="mctCalculator" />
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
// import DateRangePickerCustom from "@/components/dashboard/components/DateRangePicker.vue";
import EntityFilterOptimizer from "@/components/admin/assets/EntityFilterOptimizer.vue";
import MctCalculatorModal from "@/components/modals/MctCalculatorModal.vue";

export default {
  name: "AffiliateDashboard2",
  props: {
  },
  data() {
    return {
      mctCalculator: {},
      mctCalculatorTitle: "MCT Calculator",
      optimizerDashboard: [],
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
      currentDaterange: null,
      accessLogLimit: 250,
      networkRevenue: [],
      showOnlyWithActions: true,
      extendedView: false,
      colorRange: ['danger', 'success', 'primary', 'warning', 'info', 'danger', 'success', 'primary', 'warning', 'info', 'danger', 'success', 'primary', 'warning', 'info'],
      filterItems: [
        { text: "Info", value: "info", status: false, elements: 0 },
        { text: "Warning", value: "warning", status: false, elements: 0 },
        { text: "Danger", value: "danger", status: false, elements: 0 }
      ],
      dangerMin: 50,
      dangerMax: 150,
      warningMin: 80,
      warningMax: 120,
      generatingTable: false,
      filter: {
        campaign: this.$route.query.campaign ? [this.$route.query.campaign] : [],
        startDate: null,
        endDate: null,
      },
    };
  },
  async created() {
    this.currentDaterange = this.dateRange;
    // this.updateDashboard();
  },
  methods: {
    showMctCalculator(campaignId) {
      const campaign = this.optimizerDashboard.find((c) => c.campaign._id === campaignId);
      const salesSuite = campaign.mct_pcConversions;
      const salesShop = campaign.networkConversions;
      const mct = campaign.optimizer.mct;
      const start = new Date(this.dateRange.startDate).formatDate();
      const end = new Date(this.dateRange.endDate).formatDate();
      const optimizerId = campaign.optimizer.id;

      this.mctCalculatorTitle = `MCT Calculator for ${campaign.campaign.name}`;

      this.mctCalculator = {
        campaignId,
        optimizerId,
        salesSuite,
        salesShop,
        mct,
        start,
        end,
      };

      // open modal
      this.$bvModal.show("mct-calculator-modal");
    },
    addFilter(field, value){
      this.filter[field] = value;
    },
    pickerDate(picker){
      let str = picker.startDate ? picker.startDate.formatDate(true) : null;
      const endStr = picker.endDate ? picker.endDate.formatDate(true) : null;

      if(str !== endStr){
        str += ' - '+endStr;
      }

      return str;
    },
    async updateDashboard() {

      if (localStorage.getItem(process.env.VUE_APP_ROLE) == "systemoperator" || localStorage.getItem(process.env.VUE_APP_ROLE) == "wizard"  || localStorage.getItem(process.env.VUE_APP_ROLE) == 'affiliatemanager') {
        
        const filterData = {
          startDate: this.filter.start ? this.filter.start : this.dateRange.startDate,
          endDate: this.filter.end ? this.filter.end : this.dateRange.endDate,
          campaign: this.filter.campaign,
        };
        this.generatingTable = true;
        const data = await this.$api.optimizer.getDashboard2(filterData);

        data.map((s, index) => {
          data[index]['networkImpressions'] = !s['networkImpressions'] ? 0 : s['networkImpressions'];
          data[index]['networkClicks'] = !s['networkClicks'] ? 0 : s['networkClicks'];
          data[index]['networkClickThroughRate'] = !s['networkClickThroughRate'] ? 0 : s['networkClickThroughRate'];
          data[index]['networkConversionRate'] = !s['networkConversionRate'] ? 0 : s['networkConversionRate'];
          data[index]['shopCr'] = s['uniqueUsers'] > 0 ? s['sales']*100/s['uniqueUsers'] : 0;

          data[index]['infos'] = this.getInfos(data[index]);
          return data;
        });

        this.optimizerDashboard = data;
        this.generatingTable = false;
      }
    },
    amountOfDays(qty) {
      let startD = new Date(this.dateRange.startDate);
      let endD = new Date(this.dateRange.endDate);

      let aod = parseInt(
        (new Date(endD.getUTCFullYear(), endD.getUTCMonth(), endD.getUTCDate()) -
          new Date(startD.getUTCFullYear(), startD.getUTCMonth(), startD.getUTCDate())) /
          (1000 * 3600 * 24) +
          1
      );
      return aod * qty;
    },
    getStatus(optimizer) {
      if (optimizer.promote && optimizer.sticky) return "fa-pause text-warning";
      if (optimizer.promote) return "fa-play text-success";
      return "fa-stop text-danger";
    },
    copyIdToClipboard(str) {
      const el = document.createElement("textarea");
      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
    },
    NumRound(value, decimals=1){
      let d;
      switch(decimals){
        case 1:   d = 10;     break;
        case 2:   d = 100;    break;
        case 3:   d = 1000;   break;
        case 4:   d = 10000;  break;
        case 5:   d = 100000; break;
        default:  d = 1
      }
      return Math.round((value + Number.EPSILON) * d) / d;
    },
    getProgressVariant(value, checkValue){
      if(value == 0 || value < checkValue*this.dangerMin/100 || value > checkValue*this.dangerMax/100)  return 'danger';
      else if(value < checkValue*this.warningMin/100 || value > checkValue*this.warningMax/100)         return 'warning';
      else                                                                                              return 'success';
    },
    getLinks(data){

      const links = [];

      links.push({
        title: 'Campaign Report',
        to: `/campaign/${data.campaign._id}`,
        icon: 'fa fa-bar-chart'
      });

      links.push({
        title: 'Optimizer',
        to: `/optimizer/edit/${data.optimizer.id}`,
        icon: 'fa fa-rocket'
      });

      if(data.optimizer.detailedLogs){
        links.push({
          title: 'Detail Logs',
          to: `/campaignlogs?campaign=${data.campaign._id}`,
          icon: 'fa fa-history'
        });
      }

      data.optimizer.trackingCodes.forEach(function (obj) {
        links.push({
          title: 'Tracking Code',
          to: `/trackingcode/edit/${obj}`,
          icon: 'fa fa-code'
        });
      });

      data.optimizer.contracts.forEach(function (obj) {
        links.push({
          title: 'Contract',
          to: `/contract/edit/${obj}`,
          icon: 'fa fa-file'
        });
      });

      data.optimizer.mediaPlans.forEach(function (obj) {
        links.push({
          title: 'Media Plan',
          to: `/mediaplan/edit/${obj}`,
          icon: 'fa fa-clipboard'
        });
      });

      data.optimizer.hosts.forEach(function (obj) {
        links.push({
          title: 'Host',
          to: `/host/edit/${obj}`,
          icon: 'fa fa-flag'
        });
      });

      data.optimizer.lineItems.forEach(function (obj) {
        links.push({
          title: 'Line Item',
          to: `/lineitem/edit/${obj.id}`,
          icon: 'fa fa-cubes',
          class: obj.status ? '' : 'text-gray'
        });
      });

      data.optimizer.catalogs.forEach(function (obj) {
        links.push({
          title: 'Catalog',
          to: `/catalog/edit/${obj}`,
          icon: 'fa fa-table',
        });
      });

      return links;
    },
    getInfos(data){
      const infos = {
        info: [],
        warning: [],
        danger: []
      };

      // check API
      if(data.revenueSource !== 'direct' && (data.networkImpressions == 0 && data.impressions > 0 ||
        data.networkClicks == 0 && data.clicks > 0 ||
        data.networkConversions == 0 && data.mct_pcConversions > 0)){
          infos.info.push({ message: 'check API to '+data.revenueSource} );  
      }


      // check ctr
      if(data.networkImpressions == 0 && data.networkClicks == 0){
        infos.info.push({ message: 'no views/clicks (NET)'} );
      }

      // check budget and line items
      if(data.optimizer.lineItemsCount == 0 || data.optimizer.totalDailyBudget == 0){
        infos.warning.push({ message: 'no line item or budget is 0'} );
      }

      // check pixel
      if(data.sales == 0){
        infos.warning.push({ message: 'check sale tracking pixel'} );
      }
      else{
        if(data.ordervalue <= 0){
          infos.info.push({ message: 'sale pixel has no ordervalue'} );
        }

        // check MS
        let ms = data.mct_marketShare;
        if(data.revenueSource !== 'direct'){
          ms = data.networkMarketshare > 0 ? data.networkMarketshare : data.mct_marketShare;
        }


        //check if marketshare was met
        if (ms < data.optimizer.ms*this.warningMin/100) {
          //Marketshare zu niedrig
          if (100 / (data.sales + 1) < data.optimizer.ms) {
            //Ausreichend Sales im Shop vorhanden
            const allowedSales = data.sales/100*data.optimizer.ms;
            
            if(data.networkConversions > 0){
              if(data.networkConversions < allowedSales*this.dangerMin)       infos.danger.push({ message: 'Increase QTY', title: `only ${data.networkConversions} sales of allowed ${allowedSales} in Network`} );
              else if(data.networkConversions > allowedSales*this.dangerMax)  infos.danger.push({ message: 'Decrease QTY', title: `${data.networkConversions} sales of allowed ${allowedSales} in Network`} );
              else if(data.networkConversions < allowedSales*this.warningMin) infos.warning.push({ message: 'Increase QTY', title: `only ${data.networkConversions} sales of allowed ${allowedSales} in Network`} );
              else if(data.networkConversions > allowedSales*this.warningMin) infos.warning.push({ message: 'Decrease QTY', title: `${data.networkConversions} sales of allowed ${allowedSales} in Network`} );
            }
            else if(data.mct_pcConversions > 0){
              if(data.mct_pcConversions < allowedSales*this.dangerMin)       infos.danger.push({ message: 'Increase QTY', title: `only ${data.mct_pcConversions} sales of allowed ${allowedSales} in ADSuite`} );
              else if(data.mct_pcConversions > allowedSales*this.dangerMax)  infos.danger.push({ message: 'Decrease QTY', title: `${data.mct_pcConversions} sales of allowed ${allowedSales} in ADSuite`} );
              else if(data.mct_pcConversions < allowedSales*this.warningMin) infos.warning.push({ message: 'Increase QTY', title: `only ${data.mct_pcConversions} sales of allowed ${allowedSales} in ADSuite`} );
              else if(data.mct_pcConversions > allowedSales*this.warningMin) infos.warning.push({ message: 'Decrease QTY', title: `${data.mct_pcConversions} sales of allowed ${allowedSales} in ADSuite`} );
            }
            else if(allowedSales > 0){
              infos.danger.push({ message: 'Check Sale Pixel', title: `no sales tracked in Network and ADSuite, but the shop has sales `} );
            }

            if (data.opr < data.optimizer.qty*this.warningMin/100) {
              //Schafft nicht die geforderten OPRs
              if (!data.optimizer.sticky) {
                //Kampagne ist nicht sticky
                if (
                  typeof data.optimizer.octrkey !== 'undefined' &&
                  data.optimizer.octrkey !== '' &&
                  data.clickThroughRate !== null
                ) {
                  //oCTR key ist vorhanden
                  if (!data.optimizer.octrkey.includes('blacklist')) {
                    //oCTR steht nicht auf Blacklisting

                    if(data.uniqueUsers > 0){

                      const shopCr = data.sales*100 / data.uniqueUsers;

                      if (data.mct_pcConversionRate >= shopCr + data.optimizer.crp) {
                        //Conversion Rate ist höher als die Shop CR
                        if (data.clickThroughRate < data.optimizer.ctr*this.warningMin/100) {
                          //Erreicht nicht die erforderliche CTR
                            infos.danger.push({ message: 'CTR to low', title: `Reached a CTR of ${this.NumRound(data.clickThroughRate,2)} but need only ${data.optimizer.ctr}`} );
                        } else if (data.clickThroughRate > data.optimizer.ctr*this.warningMax/100){
                          //CTR wird erreicht
                            infos.danger.push({ message: 'Increase Impressions!', title: `Reached a CTR of ${data.clickThroughRate} but need only ${data.optimizer.ctr} ; Increase Traffic to get more Impressions`} );
                        }
                      } else {
                        //Conversion Rate bleibt kleiner als die Shop CR
                        if (data.opr == 0) {
                            infos.danger.push({ message: 'No OPR. Check!'} );
                        } else {
                          if (
                            data.optimizer.rp >= 90 &&
                            data.optimizer.nth <= 1 &&
                            !data.optimizer.uaNin.includes('linux')
                          ) {
                            if (!data.optimizer.blockerTime) {
                              infos.warning.push({ message: 'Set custom BT!'} );
                            } else if (data.optimizer.blockerTime > 3600000) {
                              infos.warning.push({ message: 'Reduce BT!'} );
                            }
                            else {
                              infos.warning.push({ message: 'MS too low! Analyze logs!'} );
                            }
                          } else if (data.optimizer.uaNin.includes('linux')) {
                            infos.warning.push({ message: 'Remove linux!'} );
                          } else {
                            infos.warning.push({ message: 'Reduce strictness!'} );
                          }
                        }
                      }
                    }
                    else{
                      // no users on shop
                      infos.danger.push({ message: 'Check Pixels'} );
                    }
                  } else {
                    //oCTR key steht auf Blacklisting
                    infos.danger.push({ message: 'Remove blacklisting!'} );
                  }
                } else {
                  //Optimizer hat keinen oCTR key
                    infos.danger.push({ message: 'Add oCTR key!'} );
                }
              } else {
                //Optimizer ist sticky
                infos.info.push({ message: 'Remove sticky!'} );
              }
            } else {
              //Erreicht die geforderten OPRs
              infos.warning.push({ message: 'Increase QTY!'} );
            }
          } else {
            //Zu wenig Sales im Shop
              infos.warning.push({ message: 'Campaign might be too small.'} );
          }
        } else {
          if(ms > data.optimizer.ms*this.warningMax/100){
            // marketshare to high
            infos.danger.push({ message: 'MS to high. Reduce QTY', title: `Reached a MS of ${this.NumRound(ms,2)} but only ${data.optimizer.ms} is allowed. Reduce QTY`} );
          }
          else{
            if(data.optimizer.qty*this.warningMax/100 > data.opr){
              infos.warning.push({ message: 'Reduce QTY', title: `Reached needed MS with less OPR. Reduce QTY to ${this.NumRound(data.optimizer.qty*this.warningMin/100)} should be OK.`} );
            }
          }
        }
      }


      if(infos.info.length > 0){
        const index = this.filterItems.findIndex(element => element.value === "info");
        this.filterItems[index].elements += 1;
      }

      if(infos.warning.length > 0){
        const index = this.filterItems.findIndex(element => element.value === "warning");
        this.filterItems[index].elements += 1;
      }

      if(infos.danger.length > 0){
        const index = this.filterItems.findIndex(element => element.value === "danger");
        this.filterItems[index].elements += 1;
      }

      return infos;
    }
  },
  computed: {
    ...mapGetters([
      "error",
      "success",
      "warning",
      "dateRange"
    ]),
    dateRange: {
      get() {
        return this.$store.state.store.dateRange;
      },
      set(value) {
        this.$store.dispatch("setDateRange", value);
      }
    },
    getRole: () => localStorage.getItem(process.env.VUE_APP_ROLE),
    tableHeaderCols(){
      return {
        campaign: this.tableFields.reduce((acc, cur) => cur.variant === 'campaign' ? ++acc : acc, 0),
        suite:    this.tableFields.reduce((acc, cur) => cur.variant === 'suite' ? ++acc : acc, 0),
        network:  this.tableFields.reduce((acc, cur) => cur.variant === 'network' ? ++acc : acc, 0),
        candc:    this.tableFields.reduce((acc, cur) => cur.variant === 'candc' ? ++acc : acc, 0),
        actions:  this.tableFields.reduce((acc, cur) => cur.variant === 'actions' ? ++acc : acc, 0),
      }
    },
    tableFields(){

      let fieldsCampaign = [
        // Campaign
        { key: "campaign_name", label: "Name", sortable: true, class: "text-left", variant: "campaign" },
        { key: "network", label: "Network", sortable: true, class: "text-center", variant: "campaign" },
        { key: "sales", label: "Sales", sortable: true, class: "text-right", variant: "campaign" },
        { key: "ordervalue", label: "Ordervalue", sortable: true, class: "text-right", variant: "campaign" },
        { key: "shopCr", label: "Shop-CR", sortable: true, class: "text-right", variant: "campaign" },
      ];

      let fieldsSuite = [
        // ADSuite
        { key: "impressions", label: "Views", sortable: true, class: "text-right", variant: "suite" },
        { key: "clicks", label: "Clicks", sortable: true, class: "text-right", variant: "suite" },
        { key: "opr", label: "OPR", sortable: true, class: "text-center", variant: "suite" },
        { key: "mct_pcConversions", label: "Sales", sortable: true, class: "text-right", variant: "suite" },
        { key: "clickThroughRate", label: "CTR", sortable: true, class: "text-center", variant: "suite" },
        { key: "mct_pcConversionRate", label: "CR", sortable: true, class: "text-center", variant: "suite" },
        { key: "mct_marketShare", label: "MS", sortable: true, class: "text-center", variant: "suite" },
      ];

      let fieldsNetwork = [
        // network
        { key: "networkImpressions", label: "Views", sortable: true, class: "text-right", variant: "network" },
        { key: "networkClicks", label: "Clicks", sortable: true, class: "text-right", variant: "network" },
        { key: "networkConversions", label: "Sales", sortable: true, class: "text-right", variant: "network" },
        { key: "networkClickThroughRate", label: "CTR", sortable: true, class: "text-center", variant: "network" },
        { key: "networkConversionRate", label: "CR", sortable: true, class: "text-center", variant: "network" },
        { key: "networkMarketshare", label: "MS", sortable: true, class: "text-center", variant: "network" },
      ];

      let fieldsCandc = [
        // costs and commission
        { key: "realCosts", label: "Costs", sortable: true, class: "text-right", variant: "candc" },
        { key: "budget", label: "Budget", sortable: true, class: "text-center", variant: "candc" },
        { key: "mct_conversionValue", label: "C-Value", sortable: true, class: "text-right", variant: "candc" },
        { key: "networkRevenue", label: "Commission", sortable: true, class: "text-right", variant: "candc" },
      ];

      let fieldsActions = [
        { key: "infos", 'label': 'Health', sortable: true, class: "text-center", variant: "actions" }
      ];


      if(!this.extendedView){
        delete fieldsCampaign[1];    // network
        delete fieldsCampaign[3];    // ordervalue
        delete fieldsCampaign[4];    // ordervalue

        delete fieldsSuite[0];      // impressions
        delete fieldsSuite[1];      // clicks
        delete fieldsSuite[2];      // opr

        delete fieldsNetwork[0];    // networkImpressions
        delete fieldsNetwork[1];    // networkClicks

        delete fieldsCandc[0];      // realCosts
        delete fieldsCandc[2];      // conversionValue
      }

      return [].concat(fieldsCampaign, fieldsSuite, fieldsNetwork, fieldsCandc, fieldsActions);
    },
    tableData(){
      let showInfo = typeof this.filterItems.find(element => element.value == 'info' && element.status == true) !== "undefined";
      let showWarning = typeof this.filterItems.find(element => element.value == 'warning' && element.status == true) !== "undefined";
      let showDanger = typeof this.filterItems.find(element => element.value == 'danger' && element.status == true) !== "undefined";

      if(!showInfo && !showWarning && !showDanger){
        return this.optimizerDashboard;
      }
      else{
        const output = [];

        for(let i = 0; i < this.optimizerDashboard.length; i++){
          if(showInfo && this.optimizerDashboard[i]?.infos?.info?.length > 0 ||
            showWarning && this.optimizerDashboard[i]?.infos?.warning?.length > 0 ||
            showDanger && this.optimizerDashboard[i]?.infos?.danger?.length > 0){
              output.push(this.optimizerDashboard[i]);
          }
        }

        return output;
      }
    }
  },
  components: {
    // DateRangePickerCustom,
    EntityFilterOptimizer,
    MctCalculatorModal
  },
};
</script>

<style> 

.daterange-chooser {
  /* border: none; */
  display: block;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.daterange-chooser .reportrange-text {
  background-color: #fff;
  /* color: #ffffff; */
  font-size: 1em;
  line-height: 1.4;
  font-weight: 400;
  padding: 13px 17px;
  /* border: none; */
  border-radius: 0px;
}

.daterange-chooser .daterangepicker {
  top: 100%;
}


.table-campaign, .table-actions{
  background-color: rgb(243,243,243);
}

.table-network{
  background-color: rgb(233,246,248);
}

.table-suite{
  background-color: rgb(254,247,238);
}

.table-candc{
  background-color: rgb(230,236,246);
}

.badge{
  border-radius: 0px;
  padding: 3px 5px;
}

.table-opt .td, .table-opt .th{
  padding: 13px 10px;
}

</style>